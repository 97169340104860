import React from 'react';
import './index.css';

function Portfolio(){
return (

<div className='bg-txtbg'>
    <div className='bg-snack bg-top bg-cover'>
        <div className='h-1'></div>

        <div className='px-[15%] mt-[10%] mb-[2%]'>
        <text className='rounded text-white text-[56px] px-2 bg-txtbg xhover:text-hovwhite'>recent works</text>
        
        <div className='text-white px-[22px] text-[25px] px-[22px] mt-[30px] mb-[20px] hover:text-hovwhite'>
            <a className='px-[5px] rounded pb-[2.5px] bg-txtbg'target='_blank' href="https://youtu.be/BzokrXWF-DE?si=HVVcFka05gVek26R">Bad Apple!! || Raymond Chen</a>
            </div>
        
        <div className='text-white px-[22px] text-[25px] px-[22px] mt-[30px] mb-[20px] hover:text-hovwhite'>
            <a className='px-[5px] rounded pb-[2.5px] bg-txtbg'target='_blank' href="https://www.youtube.com/watch?v=lBZfyoSMixI">Hiptown (where i wanna be) || Raymond Chen</a>
            </div>

        <div className='text-white px-[22px] text-[25px] px-[22px] mt-[30px] mb-[20px] hover:text-hovwhite'>
            <a className='px-[5px] rounded pb-[2.5px] bg-txtbg'target='_blank' href='https://www.youtube.com/watch?v=3-CPrPzZ8lo'>Farther || Blue Science</a>
            </div>
        
        <div className='text-white px-[22px] mt-[20px] text-[25px] mb-[45px]'>
            <a className="bg-txtbg px-[5px] rounded pb-[2.5px] hover:text-hovwhite"  href="./">grey-sato.com</a>
            </div>
        
        <div className='h-1'>
            </div>
        </div>
    </div>    
    
    <div className='h-[60px]'></div>
        <div className='flex justify-center'>
            <a className='hover:text-hovwhite p-2 flex justify-center text-white' target='#' href='https://www.instagram.com/satotoilets/'>Instagram</a>
            <div className='p-[1px]'></div>
            <a className='hover:text-hovwhite p-2 flex justify-center text-white' target='#' href='https://www.youtube.com/channel/UCTeOt2LoTTyWhUkbgz4l0wA'>YouTube</a>
        </div>
        <a className='text-white hover:text-hovwhite flex justify-center'href='mailto:greysatomusic@gmail.com'>greysatomusic@gmail.com</a>

</div>)}

export default Portfolio
