import React from 'react';
import './index.css';

function Home(){
return (

<div className='bg-txtbg'>
    <div className='bg-bush bg-top bg-cover'>
        <div className='h-1'></div>

        <div className='px-[9%] mt-[6%] mb-[2%]'>
        <text className='rounded text-white text-[56px] pb-[6.5px] pt-[2px] px-2 py-1 bg-txtbg xhover:text-hovwhite'>Grey Sato</text>
        
        <div className='text-white px-[22px] text-[25px] px-[22px] mt-[20px] mb-[14px] xhover:text-hovwhite'>
            <a className='px-[5px] rounded pb-[2.5px] bg-txtbg'>engineering,</a>
            </div>

        <div className='text-white px-[22px] text-[25px] mb-[14px] '>
            <a className='px-[5px] rounded pb-[2.5px] bg-txtbg' >saxophone,</a>
            </div>
       
        <div className='text-white px-[22px] text-[25px] mb-[14px] xhover:text-hovwhite'>
            <text className='px-[5px] rounded pb-[2.5px] bg-txtbg'>composition.</text>
            </div>
        
        <div className='text-white px-[19px] mt-[15px] text-[28px] mb-[45px]'>
            <a className="bg-txtbg px-[5px] rounded pb-[2.5px] hover:text-hovwhite"  href="./portfolio">| recent works |</a>
            </div>
        
        <div className='h-1'>
            </div>
        </div>
    </div>    
    
    <div className='h-[60px]'></div>
        <div className='flex justify-center'>
            <a className='hover:text-hovwhite p-2 flex justify-center text-white' target='#' href='https://www.instagram.com/satotoilets/'>Instagram</a>
            <div className='p-[1px]'></div>
            <a className='hover:text-hovwhite p-2 flex justify-center text-white' target='#' href='https://www.youtube.com/channel/UCTeOt2LoTTyWhUkbgz4l0wA'>YouTube</a>
        </div>
        <a className='text-white hover:text-hovwhite flex justify-center'href='mailto:greysatomusic@gmail.com'>greysatomusic@gmail.com</a>

</div>)}

export default Home